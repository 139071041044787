<template>
  <div class="menu_box" :class="{'menu_collapse': collapse}">
    <router-link to="/" class="menu_home">Amontre</router-link>
    <div class="menu_list">
      <el-menu unique-opened
               :default-active="nowMenu"
               mode="vertical"
               :show-timeout="200"
               active-text-color="$store.state.colorName"
               :collapse="collapse">
        <menu-item :menu="menuList" :nowMenu="nowMenu"></menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
import menuItem from "./components/menuItem.vue";
export default {
  data() {
    return {
    }
  },
  components: {
    menuItem
  },
  computed: {
    menuList() {
      return this.$store.state.menuList
    },
    collapse() {
      return this.$store.state.collapse
    },
    nowMenu() {
      return this.$route.name
    }
  }
}
</script>

<style lang="scss" scoped>
.menu_box {
  position: fixed;
  width: 240px;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 99;
  box-shadow: 0 1px 4px rgba(0,21,41,0.08);
  transition: all 0.6s;
  $color: var(--color);

  &.menu_collapse {
    width: 64px;
    .menu_home {
      font-size: 15px;
    }
  }
  .menu_home {
    display: block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 24px;
    color: $color;
    transition: all 0.5s;
  }

  /deep/ .el-menu {
    border: none;
  }
}
</style>
