import api from '@/api/config'

export const login = (params) => {
  return api({
    url: '/user/login',
    method: 'Post',
    data: {
      ...params,
    }
  })
}

export const isLogin = (params) => {
  return api({
    url: '/user/isLogin',
    method: 'Post',
    data: {
      ...params,
    }
  })
}

export const getCaptcha = () => {
  return api({
    url: '/user/code',
    method: 'Get',
  })
}

export const getList = (current, size, params) => {
  return api({
    url: '/user/page',
    method: 'Get',
    params: {
      current,
      size,
      ...params,
    }
  })
}

export const add = (params) => {
  return api({
    url: '/user',
    method: 'Post',
    data: {
      ...params,
    }
  })
}

export const update = (id, params) => {
  return api({
    url: `/user/${id}`,
    method: 'Patch',
    data: {
      ...params,
    }
  })
}

export const remove = (id) => {
  return api({
    url: `/user/${id}`,
    method: 'Delete',
  })
}

export const getDetail = (id) => {
  return api({
    url: `/user/${id}`,
    method: 'Get',
  })
}
