<template>
  <div class="main_box" :class="{'main_collapse': collapse}">
    <el-tabs
      v-if="$route.meta.isTab"
      v-model="mainTabsActiveName"
      class="main_content"
      :closable="true"
      @tab-click="handleChange"
      @tab-remove="handleRemove">
      <!-- <el-dropdown class="site-tabs__tools" :show-timeout="0">
        <i class="el-icon-arrow-down el-icon--right"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="tabsCloseCurrentHandle">关闭当前标签页</el-dropdown-item>
          <el-dropdown-item @click.native="tabsCloseOtherHandle">关闭其它标签页</el-dropdown-item>
          <el-dropdown-item @click.native="tabsCloseAllHandle">关闭全部标签页</el-dropdown-item>
          <el-dropdown-item @click.native="refresh()">刷新当前标签页</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
      <el-tab-pane
        v-for="item in mainTabs"
        :key="item.name"
        :label="item.title"
        :name="item.name">
        <iframe
          v-if="item.type === 'iframe'"
          :src="item.iframeUrl"
          width="100%" height="100%" frameborder="0" scrolling="yes">
        </iframe>
        <keep-alive v-else>
          <router-view v-if="item.name === mainTabsActiveName" />
        </keep-alive>
      </el-tab-pane>
    </el-tabs>
    <div v-else class="main_content">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mainTabsActiveName: '',
    }
  },
  computed: {
    mainTabs: {
      get() { return this.$store.state.mainTabs },
      set(val) { this.$store.commit('setMainTabs', val) }
    },
    collapse() {
      return this.$store.state.collapse
    },
  },
  methods: {
    handleChange(tab) {
      const tabList = this.mainTabs.filter(item => item.name === tab.name)
      if (tabList.length >= 1) {
        const tabItem = tabList[0]
        if(tabItem.name!==this.$route.name) {
          this.$router.push({ name: tabItem.name, query: tabItem.query, params: tabItem.params })
        }
      }
    },
    handleRemove(tabName) {
      this.mainTabs = this.mainTabs.filter(item => item.name !== tabName)
      if (this.mainTabs.length >= 1) {
        // 当前选中tab被删除
        if (tabName === this.mainTabsActiveName) {
          let tab = this.mainTabs[this.mainTabs.length - 1]
          this.$router.push({ name: tab.name, query: tab.query, params: tab.params }, () => {
            this.mainTabsActiveName = this.$route.name
          })
        }
      } else {
        this.menuActiveName = ''
        this.$router.push({ name: 'home' })
      }
    },
    // 路由操作修改mianTabs
    routeHandle (route) {
      if (route.meta.isTab) {
        // tab选中, 不存在先添加
        let tab = this.mainTabs.filter(item => item.name === route.name)[0]
        if (!tab) {
          tab = {
            name: route.name,
            title: route.meta.title,
            path: route.path,
            type: route.meta.iframeUrl ? 'iframe' : 'module',
            iframeUrl: route.meta.iframeUrl || '',
            params: route.params,
            query: route.query
          }
          this.mainTabs = this.mainTabs.concat(tab)
        }
        this.mainTabsActiveName = tab.name
      }
    }
  },
  watch: {
    $route: 'routeHandle'
  },
}
</script>

<style scoped lang="scss">
  .main_box {
    position: relative;
    width: 100%;
    padding: 60px 0 0 240px;
    height: 100vh;
    overflow: auto;
    transition: all 0.6s;
    &.main_collapse {
      padding: 60px 0 0 64px;
    }
    /deep/ .main_content{
      width: 100%;
      overflow: auto;
      &.el-tabs {
        padding-top: 40px;
        & > .el-tabs__header {
          position: fixed;
          left: 240px;
          width: calc(100% - 240px);
          top: 60px;
          z-index: 8;
          background: #fff;
          .el-tabs__nav-wrap {
            padding: 0 20px;
          }
        }
      }
    }
  }
</style>
