<template>
  <el-dialog title="修改信息"
             :close-on-click-modal="false"
             width="800px"
             :visible.sync="visible"
             append-to-body>
    <el-form :model="form" :rules="rules" ref="dataForm" @keyup.enter.native="formSubmit()" label-width="80px">
      <el-form-item label="用户账号" prop="account">
        <span style="padding-left: 15px">{{ form.account }}</span>
      </el-form-item>
      <el-form-item label="用户角色" prop="roleCode">
        <span style="padding-left: 15px">{{ getRoleName(form.roleCode) }}</span>
      </el-form-item>
      <el-form-item label="用户名称" prop="name">
        <el-input v-model="form.name" maxlength="15" placeholder="请输入用户名" clearable></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="form.phone" maxlength="11" placeholder="请输入手机号" clearable></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="form.password" maxlength="20" type="password" show-password placeholder="请输入密码"
                  clearable></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button type="primary" ref="formSubmit" @click="formSubmit()">确定</el-button>
      <el-button @click="visible = false">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {getDetail, add, update, isLogin} from "@/api/user";
import {getAll, getList} from "@/api/role";

export default {
  data() {
    return {
      visible: false,
      form: {
        account: '',
        roleCode: '',
        name: '',
        phone: '',
        password: '',
        order: 0,
      },
      rules: {
        name: [{required: true, message: "用户名称不能为空", trigger: "blur"}],
        phone: [{required: true, message: "手机号不能为空", trigger: "blur"}],
        password: [{required: true, message: "密码不能为空", trigger: "blur"}],
      },
      roleList: []
    }
  },
  computed: {
    account() {
      return this.$store.state.account
    },
    token() {
      return this.$store.state.token
    }
  },
  created() {
    this.getRoleList()
  },
  methods: {
    async init() {
      this.visible = true;
      isLogin({account: this.account, token: this.token}).then(data => {
        if(data.code === 200) {
          this.form = data.data.records
          console.log(this.form)
        }else {
          this.$message.warning('获取信息失败，请重新登录！')
        }
      }).catch(() => {})
    },
    async getRoleList() {
      await getAll().then(({data}) => {
        this.roleList = data.records
      })
    },
    getRoleName() {
      let obj = this.roleList.find(item => item.code === this.form.roleCode)
      if(obj) {
        return obj.name
      }else {
        return ''
      }
    },
    formSubmit() {
      this.$refs.dataForm.validate(valid => {
        if (valid) {
          if (this.form.id) {
            update(this.form.id, this.form).then(data => {
              if (data.code === 200) {
                this.$message.success(data.msg)
                this.visible = false
                this.$store.dispatch("login", this.form)
              } else {
                this.$message.error(data.msg)
              }
            })
          } else {
            add(this.form).then(data => {
              if (data.code === 200) {
                this.$emit("resetData")
                this.$message.success(data.msg)
                this.visible = false
              } else {
                this.$message.error(data.msg)
              }
            })
          }
        }
      })
    }
  }
}
</script>
