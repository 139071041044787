import api from '@/api/config'

export const getList = (current, size, params) => {
  return api({
    url: '/role/page',
    method: 'Get',
    params: {
      current,
      size,
      ...params,
    }
  })
}

export const getAll = () => {
  return api({
    url: '/role',
    method: 'Get',
  })
}

export const add = (params) => {
  return api({
    url: '/role',
    method: 'Post',
    data: {
      ...params,
    }
  })
}

export const update = (id, params) => {
  return api({
    url: `/role/${id}`,
    method: 'Patch',
    data: {
      ...params,
    }
  })
}

export const remove = (id) => {
  return api({
    url: `/role/${id}`,
    method: 'Delete',
  })
}

export const getDetail = (id) => {
  return api({
    url: `/role/${id}`,
    method: 'Get',
  })
}
