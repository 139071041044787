import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import {isLogin} from "@/api/user";
import {getMenu} from "@/api/menu";
import { Message } from 'element-ui';

Vue.use(VueRouter)
const mainRoutes = [
  {
    path: '/',
    name: 'Main',
    redirect: { name: 'home' },
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      simple:true,
    },
    component: () => import('@/pages/login')
  },
  {
    path: '/404',
    name: '404',
    meta: {
      simple:true,
    },
    component: () => import('@/pages/404')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views')
  }
]
const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  addRoutes: false,
  routes: mainRoutes
})

const addRouteList = (menuList = [], parent = '') => {
  const routeList = []
  menuList.forEach(item => {
    const route = {
      name: item.code,
      path: item.path,
      meta: {
        title: item.name,
        isTab: true,
      },
      component: null,
      children: []
    }
    if(item.children && item.children.length > 0) {
      addRouteList(item.children, item.code)
    }else {
      route.component = resolve => require([`@/views${item.path}`],resolve)
    }
    if(parent) {
      router.addRoute(route)
    }else {
      router.addRoute(parent, route)
    }
    routeList.push(route)
  })
  return routeList
}

const dynamicRouter = async () => {
  const menuData = await getMenu()
  let menuList = menuData.data.menuList
  store.commit('setMenuList', menuList)
  store.commit('setPermission', menuData.data.buttonList)
  let routeList = addRouteList(menuList)
  store.commit('setRouteList', routeList)
  router.addRoute({ path: '*', redirect: { name: '404' } })
  router.options.addRoutes = true
}
router.beforeEach(async (to, from, next) => {
  if(to.path === '/login'){
    await store.dispatch('loginOut').catch(() => {})
    return next()
  }

  const account = store.state.account
  const token = store.state.token
  if(account && token){
    const data = await isLogin({account, token})
    if(data.code === 200) {
      await store.dispatch('login', data.data.records)
      if(!router.options.addRoutes) {
        await dynamicRouter()
        return next({...to, replace: true})
      }
      return next()
    }else {
      Message.warning('请重新登录!')
      return next('/login')
    }
  }else {
    return next('/login')
  }
})

export default router
