import api from '@/api/config'

export const getList = (params) => {
  return api({
    url: '/menu/list',
    method: 'Get',
    params: {
      ...params,
    }
  })
}

export const getTree = (params) => {
  return api({
    url: '/menu/tree',
    method: 'Get',
    params: {
      ...params,
    }
  })
}

export const getMenu = (params) => {
  return api({
    url: '/permission/list',
    method: 'Get',
    params: {
      ...params,
    }
  })
}

export const getPermissionList = (roleCode) => {
  return api({
    url: `/permission/${roleCode}`,
    method: 'Get',
  })
}

export const updatePermission = (roleCode, list) => {
  return api({
    url: `/permission/update`,
    method: 'post',
    data: {
      roleCode,
      list
    }
  })
}

export const add = (params) => {
  return api({
    url: '/menu',
    method: 'Post',
    data: {
      ...params,
    }
  })
}

export const update = (id, params) => {
  return api({
    url: `/menu/${id}`,
    method: 'Patch',
    data: {
      ...params,
    }
  })
}

export const remove = (id) => {
  return api({
    url: `/menu/${id}`,
    method: 'Delete',
  })
}

export const getDetail = (id) => {
  return api({
    url: `/menu/${id}`,
    method: 'Get',
  })
}
