<template>
  <div id="app">
    <!-- simple设置单页面；notAlive设置不保存状态 -->
    <router-view v-if="$route.meta.simple"></router-view>
    <template v-else>
      <Header />
      <Menu />
      <Main />
    </template>
  </div>
</template>

<script>
import Header from '@/pages/index/header'
import Menu from '@/pages/index/menu'
import Main from '@/pages/index/main'

export default {
  components:{
    Header,
    Menu,
    Main
  },
}
</script>
