import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    account: sessionStorage.getItem('account'),
    name: '',
    roleCode: '',
    token: sessionStorage.getItem('token'),
    // 主题颜色
    colorName: '#e4601d',
    // 导航栏收缩
    collapse: false,
    // 左侧菜单
    menuList: [],
    routeList: [],
    permission: {},
    // 导航标签
    mainTabs: []
  },
  getters: {
  },
  mutations: {
    // 设置用户
    setAccount(state, data) {
      state.account = data
      sessionStorage.setItem("account", data)
    },
    setName(state, data) {
      state.name = data
    },
    setRoleCode(state, data) {
      state.roleCode = data
    },
    setToken(state, data) {
      state.token = data
      sessionStorage.setItem("token", data)
    },
    // 设置权限，不存入session，每次打开页面时查询
    setMenuList(state, data) {
      state.menuList = data
    },
    setRouteList(state, data) {
      state.routeList = data
    },
    setPermission(state, data) {
      let obj = {}
      data.forEach(item => {
        obj[item.code] = true
      })
      state.permission = obj
    },
    setColorName(state, data) {
      state.colorName = data
      sessionStorage.setItem("colorName", data)
    },
    setMainTabs(state, data) {
      state.mainTabs = data
    },
    setCollapse(state) {
      state.collapse = !state.collapse
    }
  },
  actions: {
    login(ctx,data) {
      ctx.commit("setAccount", data.account)
      ctx.commit("setName", data.name)
      ctx.commit("setRoleCode", data.roleCode)
      ctx.commit("setToken", data.token)
    },
    loginOut(ctx) {
      ctx.commit("setAccount", '')
      ctx.commit("setName", '')
      ctx.commit("setRoleCode", '')
      ctx.commit("setToken", '')
      ctx.commit("setMainTabs", [])
      sessionStorage.removeItem("account")
      sessionStorage.removeItem("token")
      // 清除动态路由, 需要在页面跳转后，否则会出现不跳转的bug
      ctx.state.routeList.forEach(item => {
        const routes = router.options.routes
        const routeIndex = routes.findIndex(route => route.name === item.name)
        router.options.routes.splice(routeIndex, 1)
      })
      router.options.addRoutes = false
      ctx.commit("setMenuList", [])
      ctx.commit("setPermission", [])
      ctx.commit("setRouteList", [])
    }
  },
  modules: {
  }
})
