<template>
  <div class="head_box" :class="{'head_collapse': collapse}">
    <div class="head_menu" :class="{'menu_collapse': collapse}">
      <i class="el-icon-s-fold" @click="handleToggle"></i>
    </div>
    <div class="head_info">
<!--      <colorPicker v-show="showColor"></colorPicker>-->
      <el-dropdown>
        <div class="el-dropdown-link">
          <div class="head_user">{{ $store.state.name }}</div>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="changeInfo"> 修改信息 </el-dropdown-item>
          <el-dropdown-item @click.native="loginOut"> 退出 </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <changeInfo ref="changeInfo"></changeInfo>
  </div>
</template>

<script>
import colorPicker from './components/colorPicker.vue';
import changeInfo from "@/pages/index/components/changeInfo.vue";
export default {
  data() {
    return {
      showColor: true,
    }
  },
  components: {
    colorPicker,
    changeInfo
  },
  computed: {
    collapse() {
      return this.$store.state.collapse
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.showColor = false
    })
  },
  methods: {
    loginOut() {
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.push('/login').catch(() => {})
      }).catch(() => {});
    },
    changeInfo() {
      this.$refs.changeInfo.init()
    },
    handleToggle() {
      this.$store.commit('setCollapse')
    }
  }
}
</script>

<style scoped lang="scss">
.head_box {
  position: fixed;
  width: calc(100% - 240px);
  height: 60px;
  top: 0;
  left: 240px;
  padding: 0 20px;
  z-index: 9;
  display: flex;
  align-items:center;
  justify-content: space-between;
  background: #fff;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.06);
  transition: all 0.6s;

  &.head_collapse {
    width: calc(100% - 64px);
    left: 64px;
  }

  .head_menu {
    font-size: 22px;
    color: #555;
    i {
      transition: all 0.6s;
      cursor: pointer;
    }
    &.menu_collapse {
      i {
        transform: rotate(180deg)
      }
    }
  }
  .head_info {
    display: flex;
    align-items: center;
    .el-dropdown-link{
      display: flex;
      align-items: center;
      cursor: pointer;
      .head_user {
        font-size:16px;
        color: #333;
        padding:0 18px;
      }
    }
  }
}
</style>
