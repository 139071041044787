import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
import './styles/common.scss';
import ElementUI from 'element-ui'
import './styles/element-variables.scss';
import dayjs from 'dayjs'
import {iconVersion, iconUrl} from "@/icon/env";
import {loadStyle} from './util'

Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$day = dayjs;
// 添加线上icon地址
iconVersion.forEach(ele => {
  loadStyle(iconUrl.replace('$key', ele));
});
Vue.prototype.$img = function(data, time){
  let imgUrl =process.env.VUE_APP_IMG + data
  if(time) {
    imgUrl = imgUrl + '?' + time
  }
  return imgUrl
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
