<template>
  <div>
    <template v-for="item of menu">
      <el-menu-item v-if="item.children && item.children.length === 0"
                    :index="item.code"
                    @click="open(item.code, item.path)"
                    :key="item.code"
                    :class="{'is-active': validActive(item.code)}">
        <i :class="item.icon"></i>
        <span slot="title" :alt="item.name">{{item.name}}</span>
      </el-menu-item>
      <el-submenu v-else
                  :index="item.code"
                  :key="item.code">
        <template slot="title">
          <i :class="item.icon"></i>
          <span slot="title" :alt="item.name">{{item.name}}</span>
        </template>
        <template v-for="child of item.children">
          <el-menu-item :index="child.code"
                        @click="open(child.code, child.path)"
                        :class="{'is-active': validActive(child.code)}"
                        v-if="child.children && child.children.length === 0"
                        :key="child.code">
            <i :class="child.icon"></i>
            <span slot="title">{{child.name}}</span>
          </el-menu-item>
          <menu-item v-else
                     :menu="[child]"
                     :nowMenu="nowMenu"
                     ></menu-item>
        </template>
      </el-submenu>
    </template>
  </div>
</template>

<script>
export default {
  name: "menuItem",
  props: ['menu', 'nowMenu'],
  methods: {
    open(code, path) {
      if(code !== this.nowMenu) {
        this.$router.push(path).catch(() => {});
      }
    },
    validActive(code) {
      return code === this.nowMenu
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-menu-item {
  &:hover, &:focus{
    background-color: #f5f5f5;
  }
  i {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    width: 24px;
    text-align: center;
    font-size: 18px;
    line-height: 1;
  }
}
/deep/ .el-submenu {
  &__title:hover, &__title:focus {
   background-color: #f5f5f5;
  }
  i {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    width: 24px;
    text-align: center;
    font-size: 18px;
    line-height: 1;
  }
}
</style>
